import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';

export class Tactical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            mComp: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getTacticalData() {
        if(this.state.mComp.length !== 0) {
            let mCompanyName = this.state.mComp.company.name;
            let mRegNumber = this.state.mComp.company.registration_number;
            let mDate = (this.state.mComp.information.creation_time).replace("T", " ");
            let mProvince = this.state.mComp.company.province;
            let mIndustry = this.state.mComp.company.industry;
            return (
                <React.Fragment>
                    <div>
                        <h4>{mCompanyName} | {mIndustry} | {mProvince} | {mRegNumber} </h4>
                        <h6>{mDate}</h6>
                    </div>
                </React.Fragment>
            );
        }
    }

    getFullTacticalReport(mData) {
        let creation_time = (mData.creation_time).replace("T", " ");
        let event_id = (mData.event_id).replaceAll(".", " ").toUpperCase();
        let src_ip = mData.information.src_ip;
        let mIncidentLevel = mData.log_level;
        mIncidentLevel = mIncidentLevel.slice(0, -1);
        mIncidentLevel = mIncidentLevel.substring(mIncidentLevel.indexOf("=") + 1);
        let log_system = mData.log_system;
        let log_time = (mData.log_time).replace("T", " ");
        let message = mData.message;
        let sensor_id = mData.sensor_id;
        let system_name = mData.system_name;

        return (
            <React.Fragment>
                <Card style={{'background': '#F0F3F6'}} title={event_id} subTitle={creation_time}>
                    <hr/>
                    <div className="p-md-12">
                        <h4>Information</h4>
                        <p>Source IP: {src_ip}</p>
                        <p>Incident Level: {mIncidentLevel}</p>
                        <p>Message: {message}</p>
                    </div>
                    <hr/>
                    <div className="p-md-12">
                        <h4>System</h4>
                        <p>Log System: {log_system}</p>
                        <p>Log Time: {log_time}</p>
                        <p>Sensor ID: {sensor_id}</p>
                        <p>System Name: {system_name}</p>
                    </div>
                </Card>
            </React.Fragment>
        );
    }

    componentDidMount() {
        if(this.props.location.report !== undefined) {
            this.productServiceDataSources.getTacticalReportsInfo(this.props.location.report).then(data => this.setState({
                items: data.events,
                mComp: data
            }));
        }
    }

    render() {
        return (
            <div className="p-grid dashboard">
                <div className="p-col-12 global-sales">
                    <h1>{this.getTacticalData()}</h1>
                    <div>
                        {this.state.items.map((data, index) => {
                            return (
                                <div className="card" key={index} style={{cursor: 'pointer'}}>
                                    {this.getFullTacticalReport(data)}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

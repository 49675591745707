import React, { useState } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppInlineProfile from './AppInlineProfile';

import { Dashboard } from './components/Dashboard';
import { Login } from './pages/Login';
import { Authkey } from './pages/Authkey';
import { Landing } from './pages/Landing';
import { Register } from './pages/Register';
import { Registration } from './pages/Registration';
import { Exchange } from './pages/Exchange';
import { CreateReport } from './pages/CreateReport';
import { DashboardBanking } from './components/DashboardBanking';
import { FormLayoutDemo } from './components/FormLayoutDemo';
import { InputDemo } from './components/InputDemo';
import { FloatLabelDemo } from './components/FloatLabelDemo';
import { InvalidStateDemo } from './components/InvalidStateDemo';
import { ButtonDemo } from './components/ButtonDemo';
import { TableDemo } from './components/TableDemo';
import { ListDemo } from './components/ListDemo';
import { TreeDemo } from './components/TreeDemo';
import { PanelDemo } from './components/PanelDemo';
import { OverlayDemo } from './components/OverlayDemo';
import { MediaDemo } from './components/MediaDemo';
import { MenuDemo } from './components/MenuDemo';
import { MessagesDemo } from './components/MessagesDemo';
import { FileDemo } from './components/FileDemo';
import { ChartDemo } from './components/ChartDemo';
import { MiscDemo } from './components/MiscDemo';
import { Documentation } from './components/Documentation';
import { IconsDemo } from './utilities/IconsDemo';
import { Widgets } from './utilities/Widgets';
import { GridDemo } from './utilities/GridDemo';
import { SpacingDemo } from './utilities/SpacingDemo';
import { ElevationDemo } from './utilities/ElevationDemo';
import { TextDemo } from './utilities/TextDemo';
import { TypographyDemo } from './utilities/TypographyDemo';
import { DisplayDemo } from './utilities/DisplayDemo';
import { FlexBoxDemo } from './utilities/FlexBoxDemo';
import { CrudDemo } from './pages/CrudDemo';
import { TimelineDemo } from './pages/TimlineDemo';
import { CalendarDemo } from './pages/CalendarDemo';
import { Invoice } from './pages/Invoice';
import { Help } from './pages/Help';
import { EmptyPage } from './pages/EmptyPage';
import { Notifications } from './components/Notifications';
import { License } from './components/License';
import { Health } from './components/Health';
import { SiemEvents } from './components/SiemEvents';
import { DataSources } from './components/DataSources';
import { Incidents } from './components/Incidents';
import { Tactical } from './components/Tactical';
import { Operational } from './components/Operational';
import { Strategic } from './components/Strategic';
import { Shared } from './components/Shared';
import { AssetManagement } from './components/AssetManagement';
import { ThreatVisualizer } from './components/ThreatVisualizer';
import { PhishingCampaign } from './components/PhishingCampaign';
import { ThreatActors } from './components/ThreatActors';
import { ThreatBulletins } from './components/ThreatBulletins';
import { Configuration } from './components/Configuration';
import { ManageNids } from './components/ManageNids';
import { ManageStack } from './components/ManageStack';
import { ManageSiem } from './components/ManageSiem';
import { EmailFiltering } from './components/EmailFiltering';
import { ManageMisp } from './components/ManageMisp';
import { CveData } from './components/CveData';
import { CveId } from './components/CveId';
import { Malware } from './components/Malware';
import { MalwareId } from './components/MalwareId';
import { Blacklist } from './components/Blacklist';
import { BlacklistId } from './components/BlacklistId';
import { PrimeIcons } from 'primereact/api';
import { TacticalList } from './components/TacticalList';
import { OperationalList } from './components/OperationalList';
import { StrategicList } from './components/StrategicList';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

const App = () => {

    const [menuActive, setMenuActive] = useState(false);
    const [profileMode, setProfileMode] = useState('inline');
    const [profileExpanded, setProfileExpanded] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [darkMenu, setDarkMenu] = useState(false);
    const [theme, setTheme] = useState('blue');
    const [layout, setLayout] = useState('blue');
    const [configActive, setConfigActive] = useState(false);

    let menuClick = false;
    let topbarItemClick = false;
    let configClick = false;

    const menu = [
        {

            label: 'Menu',
            items: [
                {
                    label: 'Dashboard', icon: 'pi pi-home pi-align-left', to: '/Dashboard',
                },
                {
                    label: 'Notifications', icon: PrimeIcons.BELL, to: '/notifications',
                },
                {
                    label: 'Create Reports', icon: PrimeIcons.FILE_PDF, to: '/createreport',
                },
                {
                    label: 'Exchange', icon: PrimeIcons.INFO_CIRCLE, to: '/exchange',
                },
                {
                    label: 'Reporting', icon: PrimeIcons.FILE_O,
                    items: [
                        {
                            label: 'Tactical Report', to: '/tacticallist',
                        },
                        {
                            label: 'Operational Report', to: '/operationallist',
                        },
                        {
                            label: 'Strategic Report', to: '/strategiclist',
                        },
                    ]
                },
                /*{
                    label: 'Collections', icon: PrimeIcons.LIST,
                    items: [
                        {
                            label: 'SIEM Events', to: '/siemevents',
                        },
                        {
                            label: 'Data Sources', to: '/datasources',
                        },
                        {
                            label: 'Incidents', to: '/incidents',
                        },
                        {
                            label: 'Asset Management', to: '/assetmanagement',
                        },
                    ]
                },*/
                {
                    label: 'Data From Sources', icon: PrimeIcons.LIST,
                    items: [
                        {
                            label: 'Common Vulnerabilities and Exposures', to: '/cvedata',
                        },
                        {
                            label: 'Malware', to: '/malware',
                        },
                        {
                            label: 'Blacklist', to: '/blacklist',
                        },
                    ]
                },
                {
                    label: 'Risk Management', icon: 'pi pi-exclamation-triangle pi-align-left',
                    items: [
                        {
                            label: 'Threat IoC Visualizer', to: '/threatvisualizer',
                        },
                        {
                            label: 'Phishing Campaign', to: '/phishingcampaign',
                        },
                        {
                            label: 'Threat Actors', to: '/threatactors',
                        },
                        {
                            label: 'Industry Threat Bulletins', to: '/threatbulletins',
                        },
                    ]
                },
                {
                    label: 'Topology', icon: PrimeIcons.SITEMAP,
                    items: [
                        {
                            label: 'Manage NIDS', to: '/managenids',
                        },
                        {
                            label: 'Manage Elastic Stack', to: '/managestack',
                        },
                        {
                            label: 'Manage SIEM', to: '/managesiem',
                        },
                        {
                            label: 'Manage Email Filtering', to: '/emailfiltering',
                        },
                        {
                            label: 'Manage MISP', to: '/managemisp',
                        },
                    ]
                },
                {
                    label: 'Administration', icon: PrimeIcons.USER_EDIT,
                    items: [
                        {
                            label: 'Register', to: '/register',
                        },
                        {
                            label: 'Configuration', to: '/configuration',
                        },
                        {
                            label: 'Health Status', to: '/health',
                        },
                        {
                            label: 'License Management', to: '/license',
                        },
                    ]
                },
            ]
        }
    ];

    const routers = [
        { path: '/dashboard', component: Dashboard},
        { path: '/dashboards/generic', component: Dashboard },
        { path: '/dashboards/dashboard_banking', component: DashboardBanking },
        { path: '/formlayout', component: FormLayoutDemo },
        { path: '/input', component: InputDemo },
        { path: '/floatlabel', component: FloatLabelDemo },
        { path: '/invalidstate', component: InvalidStateDemo },
        { path: '/button', component: ButtonDemo },
        { path: '/table', component: TableDemo },
        { path: '/list', component: ListDemo },
        { path: '/tree', component: TreeDemo },
        { path: '/panel', component: PanelDemo },
        { path: '/overlay', component: OverlayDemo },
        { path: '/media', component: MediaDemo },
        { path: '/menu', component: MenuDemo },
        { path: '/messages', component: MessagesDemo },
        { path: '/file', component: FileDemo },
        { path: '/chart', component: ChartDemo },
        { path: '/misc', component: MiscDemo },
        { path: '/icons', component: IconsDemo },
        { path: '/widgets', component: Widgets },
        { path: '/grid', component: GridDemo },
        { path: '/spacing', component: SpacingDemo },
        { path: '/elevation', component: ElevationDemo },
        { path: '/typography', component: TypographyDemo },
        { path: '/display', component: DisplayDemo },
        { path: '/flexbox', component: FlexBoxDemo },
        { path: '/text', component: TextDemo },
        { path: '/crud', component: CrudDemo },
        { path: '/timeline', component: TimelineDemo },
        { path: '/calendar', component: CalendarDemo },
        { path: '/invoice', component: Invoice },
        { path: '/help', component: Help },
        { path: '/empty', component: EmptyPage },
        { path: '/documentation', component: Documentation },
        { path: '/notification', component: Notifications },
        { path: '/health', component: Health },
        { path: '/license', component: License },
        { path: '/siemevents', component: SiemEvents },
        { path: '/datasources', component: DataSources },
        { path: '/incidents', component: Incidents },
        { path: '/tactical', component: Tactical },
        { path: '/operational', component: Operational },
        { path: '/shared', component: Shared },
        { path: '/strategic', component: Strategic },
        { path: '/assetmanagement', component: AssetManagement },
        { path: '/threatvisualizer', component: ThreatVisualizer },
        { path: '/phishingcampaign', component: PhishingCampaign },
        { path: '/threatactors', component: ThreatActors },
        { path: '/threatbulletins', component: ThreatBulletins },
        { path: '/configuration', component: Configuration },
        { path: '/managenids', component: ManageNids },
        { path: '/managestack', component: ManageStack },
        { path: '/managesiem', component: ManageSiem },
        { path: '/emailfiltering', component: EmailFiltering },
        { path: '/managemisp', component: ManageMisp },
        { path: '/cvedata', component: CveData },
        { path: '/cveid', component: CveId },
        { path: '/malware', component: Malware },
        { path: '/malwareid', component: MalwareId },
        { path: '/blacklist', component: Blacklist },
        { path: '/blacklistid', component: BlacklistId },
        { path: '/login', component: Login},
        { path: '/authkey', component: Authkey},
        { path: '/', component: Landing, exact: true},
        { path: '/register', component: Register },
        { path: '/registration', component: Registration },
        { path: '/tacticallist', component: TacticalList },
        { path: '/operationallist', component: OperationalList },
        { path: '/strategiclist', component: StrategicList },
        { path: '/exchange', component: Exchange },
        { path: '/createreport', component: CreateReport },
    ];

    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }

            hideOverlayMenu();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        menuClick = false;
        topbarItemClick = false;
        configClick = false;
    };

    const onProfileClick = (e) => {
        setProfileExpanded(prevProfileExpanded => !prevProfileExpanded);
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const onMenuButtonClick = (event) => {
        console.log("onMenuButtonClick() event: ", event);
        menuClick = true;
        setTopbarMenuActive(false);

        if (menuMode === 'overlay') {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }
        else {
            if (isDesktop())
                setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
            else
                setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevTopbarMenuActive => !prevTopbarMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onTopbarItemClick = (e) => {
        topbarItemClick = true;

        if (activeTopbarItem === e.item)
            setActiveTopbarItem(null)
        else
            setActiveTopbarItem(e.item);

        e.originalEvent.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (menuMode === 'static')
                return !staticMenuDesktopInactive;
            else if (menuMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        } else {
            if (staticMenuMobileActive)
                return true;
            else if (menuMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        setStaticMenuDesktopInactive(false);
        setOverlayMenuActive(false);

        if (e.value === 'horizontal') {
            setProfileMode('top');
        }
    };

    const onMenuColorChange = (e) => {
        setDarkMenu(e.value);
    };

    const onProfileModeChange = (e) => {
        setProfileMode(e.value);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive(prevConfigActive => !prevConfigActive);
        configClick = true;
    };

    const onLayoutColorChange = (color) => {
        setLayout(color);
    };

    const onThemeChange = (color) => {
        setTheme(color);
    };

    const isLoginPage = () => {
        return (window.location.href.endsWith("/login"));
    };

    const isAuthPage = () => {
        return (window.location.href.endsWith("/authkey"));
    };

    const isRegisterPage = () => {
        return (window.location.href.endsWith("/register")) ;
    };

    const isRegistrationPage = () => {
        return (window.location.href.endsWith("/registration")) ;
    };

    const isExchangePage = () => {
        return (window.location.href.endsWith("/exchange")) ;
    };

    const isReportCreatePage = () => {
        return (window.location.href.endsWith("/createreport")) ;
    };

    const isLandingPage = () => {
        return (window.location.href.endsWith("/#/"));
    };

    const simulateClick = (e) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (menuMode === 'overlay') {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }
        else {
            if (isDesktop())
                setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
            else
                setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
        }
    };

    let menuClassName = classNames('layout-menu-container', { 'layout-menu-dark': darkMenu, 'layout-menu-container-inactive': !isMenuVisible() });

    const topAndBottomBar = () => {
        if(isLoginPage() || isRegisterPage() || isLandingPage() || isRegistrationPage() || isAuthPage() || isExchangePage() || isReportCreatePage()) {
            return (
                <React.Fragment>
                    <div>
                        <div ref={simulateClick} onClick={()=> console.log('clicked')}></div>
                        {
                            routers.map((router, index) => {
                                if (router.exact) {
                                    return <Route key={`router${index}`} path={router.path} exact component={router.component} />
                                }
                                return <Route key={`router${index}`} path={router.path} component={router.component} />
                            })
                        }
                    </div>
                    <div className="layout-mask modal-in"></div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <AppTopBar topbarMenuActive={topbarMenuActive} profileMode={profileMode} horizontal={isHorizontal()} activeTopbarItem={activeTopbarItem}
                        onMenuButtonClick={onMenuButtonClick} onTopbarMenuButtonClick={onTopbarMenuButtonClick} onTopbarItemClick={onTopbarItemClick}></AppTopBar>
                    <div className={menuClassName} onClick={onMenuClick}>
                        <div className="menu-scroll-content">
                            {profileMode === 'inline' && menuMode !== 'horizontal' && <AppInlineProfile onProfileClick={onProfileClick} expanded={profileExpanded}></AppInlineProfile>}
                            <AppMenu model={menu} menuMode={menuMode} active={menuActive} onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick}></AppMenu>
                        </div>
                    </div>
                    <div className="layout-main">
                        {
                            routers.map((router, index) => {
                                if (router.exact) {
                                    return <Route key={`router${index}`} path={router.path} exact component={router.component} />
                                }
                                return <Route key={`router${index}`} path={router.path} component={router.component} />
                            })
                        }
                    </div>
                    <div className="layout-mask modal-in"></div>
                    <AppFooter />
                </React.Fragment>
            );
        }
    };

    let containerClassName = classNames('layout-wrapper', {
        'menu-layout-static': menuMode !== 'overlay',
        'menu-layout-overlay': menuMode === 'overlay',
        'layout-menu-overlay-active': overlayMenuActive,
        'menu-layout-slim': menuMode === 'slim',
        'menu-layout-horizontal': menuMode === 'horizontal',
        'layout-menu-static-inactive': staticMenuDesktopInactive,
        'layout-menu-static-active': staticMenuMobileActive,
    });

    return (
        <div className={containerClassName} onClick={onDocumentClick}>
            <div className="layout-wrapper-content">
                {topAndBottomBar()}
            </div>
        </div>
    );
}

export default App;


import React, { Component } from 'react';
import 'primeicons/primeicons.css';
import './Registration.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import ServiceAllApiData from '../service/ServiceAllApiData';

export class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mUserName: this.props.location.mUser.username,
            mFirstName: this.props.location.mUser.first_name,
            mLastName: this.props.location.mUser.last_name,
            mUserEmail: this.props.location.mUser.email,
            mCellNumber: this.props.location.mUser.contact_number,
            mCompanyAdmin: this.props.location.mUser.is_an_admin,
            mActive: this.props.location.mUser.is_active,
            mPassword: '',
            mConfirmPassword: ''
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    async nextPath(path, prev) {
        const registration = {
            "username": this.state.mUserName,
            "first_name": this.state.mFirstName,
            "last_name": this.state.mLastName,
            "email": this.state.mUserEmail,
            "contact_number": "12345",
            "is_active": this.state.mCompanyAdmin,
            "is_an_admin": this.state.mActive,
            "password": this.state.mPassword,
            "password2": this.state.mConfirmPassword
        };

        let mResult = 0;
        await axios.post('https://s2.csiraq-ctiep.co.za/api/v1/rest-auth/registration/', registration).then(res => {mResult = res.request.status});
        if(mResult === 200 || mResult === 201) {
            this.props.history.push({
                pathname: path
            });
        } else {
            this.props.history.push({
                pathname: prev
            });
        }
    }

    render() {
        return(
            <div className="container">
                <div className="form-box">
                    <div className="body-form">
                        <form name="Form">
                            <div className="p-grid" >
                                <div className="p-col-3" style={{ textAlign: 'left' }}>
                                    <img src="assets/layout/images/login/icon-login.svg" alt="avalon-layout" />
                                </div>
                                <div className="p-col-9" style={{ textAlign: 'left' }}>
                                    <h2 className="welcome-text">User Registration</h2>
                                </div>
                                <div className="p-col-12">
                                    <fieldset>
                                    <legend>User:</legend>
                                    <div className="p-col-12" style={{ textAlign: 'left' }}>
                                        <div className="login-input">
                                            <small className="p-error p-d-block">*User Name</small>
                                            <InputText readOnly id="userName" className="p-col-12 flatLookInputText" placeholder={this.state.mUserName} value={this.state.mUserName} />
                                        </div>
                                        <div className="login-input">
                                            <small className="p-error p-d-block">*First Name</small>
                                            <InputText readOnly id="firstName" className="p-col-12 flatLookInputText" placeholder={this.state.mFirstName} value={this.state.mFirstName} />
                                        </div>
                                        <div className="login-input">
                                            <small className="p-error p-d-block">*Last Name</small>
                                            <InputText readOnly id="lastName" className="p-col-12 flatLookInputText" placeholder={this.state.mLastName} value={this.state.mLastName} />
                                        </div>
                                        <div className="login-input">
                                            <small className="p-error p-d-block">*Email</small>
                                            <InputText readOnly id="email1" type="email" className="p-col-12 flatLookInputText" placeholder={this.state.mUserEmail} value={this.state.mUserEmail} />
                                        </div>
                                        <div className="login-input">
                                            <small className="p-error p-d-block">*Cell Number</small>
                                            <InputText readOnly id="cellNumber" className="p-col-12 flatLookInputText" placeholder={this.state.mCellNumber}  value={this.state.mCellNumber} />
                                        </div>
                                        <div className="login-input">
                                            <small className="p-error p-d-block">*Password</small>
                                            <InputText id="passwordEnter" type="password" className="p-col-12 flatLookInputText" placeholder="Password" value={this.state.mPassword} onChange={(e) => this.setState({mPassword: e.target.value})} />
                                            <small className="p-error p-d-block">*Confirm Password</small>
                                            <InputText id="passwordConfirm" type="password" className="p-col-12 flatLookInputText" placeholder="Confirm Password" value={this.state.mConfirmPassword} onChange={(e) => this.setState({mConfirmPassword: e.target.value})}/>
                                        </div>
                                    </div>
                                    </fieldset>
                                </div>
                                <div className="p-col-12 button-pane" style={{ textAlign: 'center' }}>
                                    <Button label="Register" onClick={ (e) => {
                                        e.preventDefault();
                                        this.nextPath('/login', '/');
                                    }}/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ServiceAllApiData from '../service/ServiceAllApiData';

export class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceNotifications = new ServiceAllApiData();
    }

    componentDidMount() {
        this.productServiceNotifications.getNotifications().then(data => this.setState({ items: data }));
    }

    render() {
        return (
            <div>
                <h1>Notifications and Alarm Activity</h1>
                <div className="card">
                    <DataTable value={this.state.items}>
                        <Column field="type" header="Type"></Column>
                        <Column field="description" header="Description"></Column>
                        <Column field="date" header="Date"></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}

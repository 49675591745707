import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';

export class BlacklistId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getBlacklistInfoById() {
        if(this.state.items.length !== 0) {
            let mId = this.state.items.id;
            let mDate = (this.state.items.date_added).replace("T", " ");
            let mUrl = this.state.items.url;
            let mUrlStatus = this.state.items.url_status;
            let mThreat = this.state.items.threat;
            let mTags = this.state.items.tags;
            let mReporter = this.state.items.reporter;
            let mUrlhause_link = this.state.items.urlhause_link;

            return (
                <React.Fragment>
                    <Card style={{'background': '#FFFFFF'}} title="Blacklisted Url Detail" subTitle={mDate}>
                        <p>Url: {mUrl}</p>
                        <p>Url Status: {mUrlStatus} </p>
                        <p>Threat: {mThreat} </p>
                        <p>Tags: {mTags} </p>
                        <p>Reporter: {mReporter} </p>
                        <p>Hause Link Url: {mUrlhause_link} </p>
                    </Card>
                </React.Fragment>
            );
        }
    }

    componentDidMount() {
        if(this.props.location.cveData !== undefined) {
            this.productServiceDataSources.getBlacklistIdSourcesInfo(this.props.location.cveData).then(data => this.setState({ items: data }));
        }
    }

    render() {
        return (
            <div>
                <div className="card">
                    {this.getBlacklistInfoById()}
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';

export class StrategicList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getReports(mData) {
        let mId = mData.id;
        let mReportId = mData.information.report_id;
        let mCompanyName = mData.company.name;
        let mRegNumber = mData.company.registration_number;
        let mIncidentLevel = mData.incident_level.threat_level;
        mIncidentLevel = mIncidentLevel.slice(0, -1);
        mIncidentLevel = mIncidentLevel.substring(mIncidentLevel.indexOf("=") + 1);
        let mDate = (mData.information.creation_time).replace("T", " ");
        let mProvince = mData.company.province;
        let mIndustry = mData.company.industry;
        let mTimeFrom = (mData.information.time_from).replace("T", " ");;
        let mTimeTo = (mData.information.time_to).replace("T", " ");;
        let mTime = mTimeFrom + " | " + mTimeTo;
        return (
            <React.Fragment>
                <Card style={{'background': '#F0F3F6'}} title={mCompanyName} subTitle={mDate}>
                    <h6>{mProvince} | {mIndustry}</h6>
                    <hr/>
                    <div className="p-md-12">
                        <p>Incident Level: {mIncidentLevel}</p>
                        <p>Company Registration: {mRegNumber}</p>
                        <p>Time From: {mTimeFrom}</p>
                        <p>Time To: {mTimeTo}</p>
                    </div>
                </Card>
            </React.Fragment>
        );
    }

    nextPath(path, report) {
        this.props.history.push({
            pathname: path+"/"+report,
            report: report
        });
    }

    componentDidMount() {
        this.productServiceDataSources.getStrategicReportsData(this.state.session).then(data => this.setState({ items: data }));
    }

    render() {
        return (
            <div>
                <h1>Strategic Reports</h1>
                {this.state.items.map((data, index) => {
                    return (
                        <div className="card" key={index} onClick={() => this.nextPath("/strategic", data.id)} style={{cursor: 'pointer'}} >
                            {this.getReports(data)}
                        </div>
                    )
                })}
            </div>
        );
    }
}
import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';

export class EmailFiltering extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceNotifications = new ServiceAllApiData();
    }

    componentDidMount() {
        this.productServiceNotifications.getNotifications().then(data => this.setState({ items: data }));
    }

    render() {
        return (
            <div>
                <h1>Email Filtering</h1>
            </div>
        );
    }
}

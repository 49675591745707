import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';

export class MalwareId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getMalwareInfoById() {
        if(this.state.items.length !== 0) {
            let mId = this.state.items.id;
            let mFileName = this.state.items.file_name;
            let mDate = (this.state.items.first_seen_utc).replace("T", " ");
            let mClamav = this.state.items.clamav;
            let mSha256_hash = this.state.items.sha256_hash;
            let mMd5_hash = this.state.items.md5_hash;
            let mSha1_hash = this.state.items.sha1_hash;
            let mReporter = this.state.items.reporter;
            let mFile_type = this.state.items.file_type;
            let mMime_type = this.state.items.mime_type;
            let mSignature = this.state.items.signature;
            let mVtpercent = this.state.items.vtpercent;
            let mImphash = this.state.items.imphash;
            let mSsdeep = this.state.items.ssdeep;
            let mTlsh = this.state.items.tlsh;


            return (
                <React.Fragment>
                    <Card style={{'background': '#FFFFFF'}} title="Malware Detail" subTitle={mDate}>
                        <p>file_name: {mFileName}</p>
                        <p>Sha256_hash: {mSha256_hash} </p>
                        <p>Clamav: {mClamav} </p>
                        <p>Md5_hash: {mMd5_hash} </p>
                        <p>Sha1_hash: {mSha1_hash} </p>
                        <p>Reporter: {mReporter} </p>
                        <p>File_type: {mFile_type} </p>
                        <p>Mime_type: {mMime_type} </p>
                        <p>Signature: {mSignature} </p>
                        <p>Vtpercent: {mVtpercent} </p>
                        <p>Imphash: {mImphash} </p>
                        <p>Ssdeep: {mSsdeep} </p>
                        <p>Tlsh: {mTlsh} </p>
                    </Card>
                </React.Fragment>
            );
        }
    }

    componentDidMount() {
        if(this.props.location.cveData !== undefined) {
            this.productServiceDataSources.getMalwareIdSourcesInfo(this.props.location.cveData).then(data => this.setState({ items: data }));
        }
    }

    render() {
        return (
            <div>
                <div className="card">
                    {this.getMalwareInfoById()}
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ServiceAllApiData from '../service/ServiceAllApiData';

export class SiemEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: []
        };

        this.productServiceSiemEvents = new ServiceAllApiData();
    }

    getSiemEventSeverity(thisState) {
        let state_level = null;
        if(thisState.level === "Information") {
            state_level = "../assets/demo/images/level/Information.png";
        } else if(thisState.level === "Critical") {
            state_level = "../assets/demo/images/level/Critical.png";
        } else if(thisState.level === "Warning") {
            state_level = "../assets/demo/images/level/Warning.png";
        }

        return (
            <React.Fragment>
                <img alt="state" src={state_level} width={75} />
            </React.Fragment>
        );
    }

    componentDidMount() {
        this.productServiceSiemEvents.getSiemEventInfo().then(data => this.setState({ events: data }));
    }

    render() {
        return (
            <div>
                <h1>Siem Events</h1>
                <div className="card">
                    <DataTable value={this.state.events}>
                        <Column field="source" header="Device IP"></Column>
                        <Column field="name" header="Device Name"></Column>
                        <Column field="event" header="Event"></Column>
                        <Column field="level" header="Severity" body={this.getSiemEventSeverity}></Column>
                        <Column field="date" header="Date"></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}
import axios from 'axios';
import React, { Component } from 'react';

export default class ServiceAllApiData extends Component {
    static mKey = '';

    async setGlobalKey(myKey) {
        ServiceAllApiData.mKey = myKey;
    }

    getSiemEventInfo() {
        return axios.get('assets/demo/data/siem-events.json').then((res) => res.data.data);
    }

    getIncidentsInfo() {
        return axios.get('assets/demo/data/incidents.json').then((res) => res.data.data);
    }

    getDataSourcesInfo() {
        return axios.get('assets/demo/data/data-sources.json').then((res) => res.data.data);
    }

    getNotifications() {
        return axios.get('assets/demo/data/notifications.json').then((res) => res.data.data);
    }

    getReportingData() {
        return axios.get('assets/demo/data/reporting.json').then((res) => res.data.data);
    }

    async getCveDataSourcesInfo() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://ctiepapi.urithi.co.za/api/v1/cves/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getCveIdSourcesInfo(cveId) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://ctiepapi.urithi.co.za/api/v1/cves/'.concat(cveId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);

    }

    async getMalwareDataSourcesInfo() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://ctiepapi.urithi.co.za/api/v1/malware/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getBlacklistDataSourcesInfo() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://ctiepapi.urithi.co.za/api/v1/urlblacklist/' , {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getOperationalReportsData() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2.csiraq-ctiep.co.za/api/v1/reports/operational/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getTacticalReportsData() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2.csiraq-ctiep.co.za/api/v1/reports/tactical/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getStrategicReportsData() {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2.csiraq-ctiep.co.za/api/v1/reports/strategic/', {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getTacticalReportsInfo(report) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2.csiraq-ctiep.co.za/api/v1/reports/tactical/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getOperationalReportsInfo(report) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2.csiraq-ctiep.co.za/api/v1/reports/operational/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getStrategicReportsInfo(report) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://s2.csiraq-ctiep.co.za/api/v1/reports/strategic/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getReportIdInfo(report) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://ctiepapi.urithi.co.za/api/v1/cves/'.concat(report).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);

    }

    async getMalwareIdSourcesInfo(malwareId) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://ctiepapi.urithi.co.za/api/v1/malware/'.concat(malwareId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getBlacklistIdSourcesInfo(blacklistId) {
        let mKeyResult = ServiceAllApiData.mKey;
        return axios.get('https://ctiepapi.urithi.co.za/api/v1/urlblacklist/'.concat(blacklistId).concat('/'), {'headers': {'Authorization': 'Token '.concat(mKeyResult)}}).then((res) => res.data);
    }

    async getIndustriesInfo() {
        return axios.get('https://ctiepapi.urithi.co.za/api/v1/accounts/industries/').then(res => res.data);
    }

    async getProvincesInfo() {
        return axios.get('https://ctiepapi.urithi.co.za/api/v1/accounts/provinces/').then(res => res.data);
    }
}

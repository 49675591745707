import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/components/chart/Chart';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';

export const Dashboard = () => {
    const [userDataGender, setUserDataGender] = useState({});
    const [userData, setUserData]  = useState({});
    const [userTestDataX, setUserTestDataX]  = useState({});
    const mapPosition = [-25.747868, 28.229271]; //[51.505, -0.09];
    const bgColor = ['rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
    ];

    const bdColor = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
    ];

    const hoverBgColor = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#1FDE56"
    ];

    const [selectedIndustry, setIndustry] = useState(null);

    const industry = [
        { name: 'Banking', code: 'BK'},
        { name: 'Health', code: 'HT' },
        { name: 'Education', code: 'ED' },
        { name: 'Defence', code: 'DF' }
    ];

    const bdWidth = 1;

    const chartData = {
        labels: ['UserLogon', 'UserLogoff', 'ScopeChange', 'MachineLogon', 'MachineLogoff', 'FileDataWrite', 'ServiceStart', 'SereviceStop'],
        datasets: [
            {
                data: [18, 11, 8, 9, 19, 12, 14, 9],
                backgroundColor: bgColor,
                hoverBackgroundColor: hoverBgColor
            }
        ]
    };

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    const basicData = {
        labels: ['Critical', 'Warning', 'Info', 'Medium'],
        datasets: [
            {
                label: 'Original Dataset',
                data: [65, 59, 80, 87],
                backgroundColor: bgColor
            }
        ]
    };

    const generateColor = () => {
        let r = 1 + (Math.random() * 100) % 255;
        let g = 1 + (Math.random() * 100) % 255;
        let b = 1 + (Math.random() * 100) % 255;
        let a = 0.8;
        return 'rgba(' + r + ', ' + g + ',' + b + ',' + a + ')';
    }

    let colorArray = [];
    let provinces = ['Limpopo', 'North West', 'Gauteng', 'Western Cape', 'KwaZulu Natal', 'Free State', 'Mpumalanga', 'Northern Cape', 'Eastern Cape'];
    provinces.forEach(() => {
        colorArray.push(generateColor());
    });

    const incidents = {
        labels: provinces,
        datasets: [
            {
                label: 'Original Dataset',
                data: [35, 29, 40, 47, 38, 25, 31, 15, 9],
                backgroundColor: colorArray
            }
        ]
    };

    const portInfo = {
        labels: ['443', '80', '445', 'Other'],
        datasets: [
            {
                label: 'Original Dataset',
                data: [17, 8, 23, 31],
                backgroundColor: bgColor
            }
        ]
    };

    var options_api = {
        method: 'GET',
        url: 'https://api.agify.io?name=Test',
    };

    var test_api = {
        method: 'GET',
        url: '../assets/demo/data/test-api.json',
    };

    const chartDataLoginFailure = {
        labels: ['LM_Server', 'IE11Win7', 'DS0VFGI', 'i7Geek-2015'],
        datasets: [
            {
                data: [138, 50, 100, 73],
                backgroundColor: bgColor,
                hoverBackgroundColor: hoverBgColor
            }
        ]
    };

    const lightOptionsLoginFailure = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    const chartDataIncidents = {
        labels: ['Invalid DNS Req', 'Phishing', 'Malware'],
        datasets: [
            {
                data: [11, 8, 17],
                backgroundColor: bgColor,
                hoverBackgroundColor: hoverBgColor
            }
        ]
    };

    const lightOptionsIncidents = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    const ApiData = () => {
        axios.request(options_api)
        .then(res=> {
            setUserData({
                labels: [res.data.name],
                datasets: [{
                    label: 'Name vs Age',
                    data: [res.data.age],
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const space = " ";

    const TestApi = () => {
        axios.request(test_api)
        .then(res=> {
            var items_x = [];
            var items_y = [];
            for(let i = 0; i < res.data.data.length; i++) {
                items_x.push(res.data.data[i].type);
                items_y.push(res.data.data[i].value);
            }

            setUserTestDataX({
                labels: items_x,
                datasets: [
                {
                    data: items_y,
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const onIndustryChange = (e) => {
        setIndustry(e.value);
    }

    const ApiDataGender = () => {
        let males = 0;
        let females = 0;

        axios.get("https://randomuser.me/api")
        .then(res => {
            for(const dataObj of res.data.results) {
                if(dataObj.gender === "male") {
                    males++;
                } else if(dataObj.gender === "female") {
                    females++;
                }
            }

            setUserDataGender({
                labels: ['Male', 'Female'],
                datasets: [{
                    label: 'Gender Data',
                    data: [males, females],
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })

    }
    useEffect(() => {
        ApiData();
        TestApi();
        ApiDataGender();
    }, []);

    return (
        <div className="p-grid dashboard">
            <div className="p-col-12 p-md-6 p-lg-3">
                <div className="p-grid overview-box overview-box-1">
                    <div className="overview-box-title">
                        <i className="pi pi-users"></i>
                        <span>Online Users</span>
                    </div>
                    <div className="overview-box-count">+ 5</div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
                <div className="p-grid overview-box overview-box-1">
                    <div className="overview-box-title">
                        <i className="pi pi-ticket"></i>
                        <span>Incidents</span>
                    </div>
                    <div className="overview-box-count">This week: 15</div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
                <div className="p-grid overview-box overview-box-1">
                    <div className="overview-box-title">
                        <i className="pi pi-eye"></i>
                        <span>Watch List</span>
                    </div>
                    <div className="overview-box-count">2450</div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-3">
                <div className="p-grid overview-box overview-box-1">
                    <div className="overview-box-title">
                        <i className="pi pi-map-marker"></i>
                        <span>Top Contributing Region</span>
                    </div>
                    <div className="overview-box-count">450</div>
                </div>
            </div>

            <div className="p-col-12 p-lg-4 global-sales">
                <div className="card">
                    <h5>Incidents Per Provice</h5>
                    <Chart
                        type="bar"
                        data={incidents}
                        options={{
                            legend: {
                                display: false
                            },
                            responsive:true,
                            title: { text: "Incidents Per Province", display: true },
                            scales:{
                                yAxes:[{
                                    ticks:{
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-4 global-sales">
                <div className="card">
                    <h5>Incident Classification By Industry: {space}
                        <Dropdown value={selectedIndustry} options={industry} onChange={onIndustryChange} optionLabel="name" placeholder="Select Industry"/>
                    </h5>

                    <Chart type="doughnut" data={chartDataIncidents} options={lightOptionsIncidents}/>
                </div>
            </div>

            <div className="p-col-12 p-lg-4 global-sales">
                <div className="card">
                    <h5>Incident Severity % Distribution</h5>
                    <Chart type="bar" data={basicData}/>
                </div>
            </div>

            <div className="p-col-12">
                <div className="card">
                    <h5>National Threat Heat Map</h5>
                    <MapContainer
                        center={mapPosition}
                        zoom={10}
                        scrollWheelZoom={false}
                        style={{ height:450, width:"100%"}}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </MapContainer>
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-4 global-sales">
                <div className="card">
                    <h5>Login Failure By Machine</h5>
                    <Chart type="doughnut" data={chartDataLoginFailure} options={lightOptionsLoginFailure}/>
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-4 global-sales">
                <div className="card">
                    <h5>Traffic By Destination Port</h5>
                    <Chart type="bar" data={portInfo}/>
                </div>
            </div>

            <div className="p-col-12 p-lg-4 global-sales">
                <div className="card">
                    <h5>Events by Event Type</h5>
                    <Chart type="pie" data={chartData} options={lightOptions}/>
                </div>
            </div>
    </div>
    );
}

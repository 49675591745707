import React, { Component } from 'react';
import './Exchange.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import axios from 'axios';

export class Exchange extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    async nextPath(path) {
        let mKeyResult = '';
        let mKeyResult1 = '';
        let mKeyResult2 = '';
        await axios.get('https://s2.csiraq-ctiep.co.za/api/v1/exchange/request-report/?report_type=STRATEGIC&url=s2.csiraq-ctiep.co.za').then((res) => {mKeyResult = res});
        await axios.get('https://s2.csiraq-ctiep.co.za/api/v1/exchange/request-report/?report_type=OPERATIONAL&url=s2.csiraq-ctiep.co.za').then((res) => {mKeyResult = res});
        await axios.get('https://s2.csiraq-ctiep.co.za/api/v1/exchange/request-report/?report_type=TACTICAL&url=s.csiraq-ctiep.co.za').then((res) => {mKeyResult = res});
        if((mKeyResult === 200 || mKeyResult === 201) &&
            (mKeyResult1=== 200 || mKeyResult1 === 201) &&
            (mKeyResult2 === 200 || mKeyResult2 === 201))
        {
            this.props.history.push({
                pathname: path
            });
        }
    }

    async componentDidMount() {

    }

    render() {
        return (
            <header>
                <div className="intro-logo jumbo-bg">
                    <h1>Cyber Threat Intelligence Exchange Platform</h1>
                    <div className="p-lg-12 button-pane spacer">
                        <p>Click below to perform data exchange bewteen platforms</p>
                        <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Perform Exchange" onClick={(e) => {
                            e.preventDefault();
                            this.nextPath('/login');
                        }} />
                    </div>
                </div>
            </header>
        );
    }
}

import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ServiceAllApiData from '../service/ServiceAllApiData';

export class Blacklist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
        this.onRowSelect = this.onRowSelect.bind(this);
    }

    onRowSelect(event) {
        this.nextPath('/blacklistid', event.data.id);
    }

    nextPath(path, blacklistId) {
        this.props.history.push({
            pathname: path,
            cveData: blacklistId
        });
    }

    getFormattedTime(thisState) {
        let mDate = (thisState.date_added).replace("T", " ");
        return (
            <React.Fragment>
                <p>{mDate}</p>
            </React.Fragment>
        );
    }

    componentDidMount() {
        this.productServiceDataSources.getBlacklistDataSourcesInfo().then(data => this.setState({ items: data }));
    }

    render() {
        return (
            <div>
                <h1>Blacklisted Url List</h1>
                <div className="card">
                    <DataTable value={this.state.items} selectionMode="single" selection={this.state.selectedItem} onSelectionChange={e => this.setState({ selectedItem: e.value })}
                        dataKey="id" onRowSelect={this.onRowSelect}>
                        <Column field="url" header="Url"></Column>
                        <Column field="url_status" header="Url Status"></Column>
                        <Column field="reporter" header="Reporter"></Column>
                        <Column field="date_added" header="Date Added" body={this.getFormattedTime}></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import L, {Icon} from 'leaflet';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});

export class Strategic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            mComp: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getStrategicData() {
        if(this.state.mComp.length !== 0) {
            let mCompanyName = this.state.mComp.company.name;
            let mRegNumber = this.state.mComp.company.registration_number;
            let mIncidentLevel = this.state.mComp.incident_level.threat_level;
            mIncidentLevel = mIncidentLevel.slice(0, -1);
            mIncidentLevel = mIncidentLevel.substring(mIncidentLevel.indexOf("=") + 1);
            let mDate = (this.state.mComp.information.creation_time).replace("T", " ");
            let mProvince = this.state.mComp.company.province;
            let mIndustry = this.state.mComp.company.industry;
            let mTimeFrom = (this.state.mComp.information.time_from).replace("T", " ");;
            let mTimeTo = (this.state.mComp.information.time_to).replace("T", " ");
            return (
                <React.Fragment>
                    <Card style={{'background': '#F0F3F6'}} title={mCompanyName} subTitle={mDate}>
                        <h6>{mProvince} | {mIndustry}</h6>
                        <hr/>
                        <div className="p-md-12">
                            <p>Incident Level: {mIncidentLevel}</p>
                            <p>Company Registration: {mRegNumber}</p>
                            <p>Time From: {mTimeFrom}</p>
                            <p>Time To: {mTimeTo}</p>
                        </div>
                    </Card>
                </React.Fragment>
            );
        }
    }

    componentDidMount() {
        if(this.props.location.report !== undefined) {
            this.productServiceDataSources.getStrategicReportsInfo(this.props.location.report).then(data => this.setState({
                items: data.threat_actors.actors,
                mComp: data
            }));
        }
    }

    render() {
        let mapCenterPosition = [36.0682, -80.2928];
        return (
            <div className="p-grid dashboard">
                <div className="p-col-12 global-sales">
                    {this.getStrategicData()}
                    <br/>
                    <div>
                    <h1>Visualize Threat Actors</h1>
                        <MapContainer center={mapCenterPosition} zoom={2} scrollWheelZoom={false} style={{ height:450, width:"100%"}}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {this.state.items.map((data, index) => {
                                return (
                                    <Marker
                                        key={index}
                                        position={{ lat: data.lat, lng: data.lon }}
                                        icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
                                    >
                                        <Popup>
                                        <span>test</span>
                                        </Popup>
                                    </Marker>
                                );
                            })}
                        </MapContainer>
                    </div>
                    <br/>
                    <h1>Cyber Actors</h1>
                    <div className="card">
                        <DataTable value={this.state.items} selectionMode="single" responsiveLayout="scroll">
                            <Column field="country" header="Country"></Column>
                            <Column field="region" header="Region"></Column>
                            <Column field="city" header="City"></Column>
                            <Column field="ip" header="IP Address"></Column>
                            <Column field="num_of_detections" header="Number of Detections"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}
import React, { Component }  from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import './Login.css';
import ServiceAllApiData from '../service/ServiceAllApiData';

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mUsername: '',
            mPassword: ''
        };
        this.productServiceDataSources = new ServiceAllApiData();
    }

    async nextPath(path) {
        const login = {'username': this.state.mUsername, 'password': this.state.mPassword};
        let mResult = '';
        let mKey = "";
        await axios.post('https://s1.csiraq-ctiep.co.za/api/v1/rest-auth/login/', login).then(res => {
            mKey = res.data.key;
            mResult = res.request.status
        });
        if(mResult === 200 || mResult === 201) {
            this.productServiceDataSources.setGlobalKey(mKey);
            this.props.history.push({
                pathname: path
            });
        } else {
            this.props.history.push({
                pathname: this
            });
        }
    }

    render() {
        return (
            <div className="login-body">
                <div className="login-hero"></div>
                <div className="card login-panel p-fluid">
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-3" style={{ textAlign: 'left' }}>
                                <img src="assets/layout/images/login/icon-login.svg" alt="avalon-layout" />
                            </div>
                            <div className="p-col-9" style={{ textAlign: 'left' }}>
                                <h2 className="welcome-text">Welcome</h2>
                                <span className="guest-sign-in">Sign in to CTIEP</span>
                            </div>
                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                <label className="login-label">Username</label>
                                <div className="login-input">
                                    <InputText placeholder="username" value={this.state.mUsername} onChange={(e) => this.setState({mUsername: e.target.value})} />
                                </div>
                            </div>
                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                <label className="login-label">Password</label>
                                <div className="login-input">
                                    <InputText type="password" placeholder="Password" value={this.state.mPassword} onChange={(e) => this.setState({mPassword: e.target.value})} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 button-pane">
                                <Button label="Sign In" onClick={(e) => {
                                    e.preventDefault();
                                    this.nextPath('/dashboard')
                                }} />
                            </div>
                            <div className="p-col-12 p-md-6 link-pane">
                                <button className="p-link">Forgot Password?</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

import React, { Component } from 'react';
import './Exchange.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import axios from 'axios';

export class CreateReport extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    async nextPathTactical(path) {
        let mKeyResult = '';
        await axios.get('https://s2.csiraq-ctiep.co.za/api/v1/reports/tactical/createreport/').then((res) => {mKeyResult = res});
        if(mKeyResult === 200 || mKeyResult === 201) {
            this.props.history.push({
                pathname: path
            });
        }
    }

    async nextPathOperational(path) {
        let mKeyResult = '';
        await axios.get('https://s2.csiraq-ctiep.co.za/api/v1/reports/operational/create/').then((res) => {mKeyResult = res});
        if(mKeyResult === 200 || mKeyResult === 201) {
            this.props.history.push({
                pathname: path
            });
        }
    }

    async nextPathStrategic(path) {
        let mKeyResult = '';
        await axios.get('https://s2.csiraq-ctiep.co.za/api/v1/reports/strategic/create/').then((res) => {mKeyResult = res});
        if(mKeyResult === 200 || mKeyResult === 201) {
            this.props.history.push({
                pathname: path
            });
        }
    }

    async componentDidMount() {

    }

    render() {
        return (
            <header>
                <div className="intro-logo jumbo-bg">
                    <h1>Cyber Threat Intelligence Exchange Platform</h1>
                    <div className="p-lg-12 button-pane spacer">
                        <p>Click below to generate reports</p>
                        <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Tactical Report" onClick={(e) => {
                            e.preventDefault();
                            this.nextPathTactical('/tacticallist');
                        }} />
                        <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Operational" onClick={(e) => {
                            e.preventDefault();
                            this.nextPathOperational('/operationallist');
                        }} />
                        <Button className="p-button-warning p-button-raised p-button-rounded btn-block custom" label="Strategic Reports" onClick={(e) => {
                            e.preventDefault();
                            this.nextPathStrategic('/strategiclist');
                        }} />
                    </div>
                </div>
            </header>
        );
    }
}

import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Card } from 'primereact/card';

export class CveData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };

        this.productServiceDataSources = new ServiceAllApiData();
    }

    getCve(mData) {
        let mCveId = mData.cve.cve_data_meta.cve_id;
        let mCveDescription = mData.cve.description.description_data[0].value;
        let mDate = (mData.publishedDate).replace("T", " ");
        let mScore = "Impact score: " + mData.impact.baseMetricV3.impactScore;
        let xScore = "Exploitability score: " + mData.impact.baseMetricV3.exploitabilityScore;
        let mScores = mScore + " | " + xScore;
        return (
            <React.Fragment>
                <Card style={{'background': '#F0F3F6'}} title={mCveId} subTitle={mDate}>
                    {mCveDescription}
                    <hr/>
                    <br/>
                    <p style={{'float': 'right'}}>{mScores}</p>
                </Card>
            </React.Fragment>
        );
    }

    nextPath(path, cveId) {
        this.props.history.push({
            pathname: path,
            cveData: cveId
        });
    }

    componentDidMount() {
        this.productServiceDataSources.getCveDataSourcesInfo(this.state.session).then(data => this.setState({ items: data }));
    }

    render() {
        return (
            <div>
                <h1>Common Vulnerabilities and Exposures</h1>
                {this.state.items.map((data, index) => {
                    return (
                        <div className="card" key={index} onClick={() => this.nextPath('/cveid', data.id)} style={{cursor: 'pointer'}}>
                            {this.getCve(data)}
                        </div>
                    )
                })}
            </div>
        );
    }
}
import React, { Component } from 'react';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { Chart } from 'primereact/components/chart/Chart';
import { Card } from 'primereact/card';

export class Operational extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
        this.productServiceDataSources = new ServiceAllApiData();
    }

    getOperationalData() {
        if(this.state.items.length !== 0) {
            let mId = this.state.items.id;
            let mReportId = this.state.items.information.report_id;
            let mCompanyName = this.state.items.company.name;
            let mRegNumber = this.state.items.company.registration_number;
            let mIncidentLevel = this.state.items.incident_level.threat_level;
            mIncidentLevel = mIncidentLevel.slice(0, -1);
            mIncidentLevel = mIncidentLevel.substring(mIncidentLevel.indexOf("=") + 1);
            let mDate = (this.state.items.information.creation_time).replace("T", " ");
            let mProvince = this.state.items.company.province;
            let mIndustry = this.state.items.company.industry;
            let mTimeFrom = (this.state.items.information.time_from).replace("T", " ");;
            let mTimeTo = (this.state.items.information.time_to).replace("T", " ");;
            let mTime = mTimeFrom + " | " + mTimeTo;
            return (
                <React.Fragment>
                    <Card style={{'background': '#F0F3F6'}} title={mCompanyName} subTitle={mDate}>
                        <h6>{mProvince} | {mIndustry}</h6>
                        <hr/>
                        <div className="p-md-12">
                            <p>Incident Level: {mIncidentLevel}</p>
                            <p>Company Registration: {mRegNumber}</p>
                            <p>Time From: {mTimeFrom}</p>
                            <p>Time To: {mTimeTo}</p>
                        </div>
                    </Card>
                </React.Fragment>
            );
        }
    }

    generateColor() {
        let r = parseInt((1 + (Math.random() * 100) % 255), 10);
        let g = parseInt((11 + (Math.random() * 100) % 255), 10);
        let b = parseInt((11 + (Math.random() * 100) % 255), 10);
        let a = 0.8;
        return 'rgba(' + r + ', ' + g + ',' + b + ',' + a + ')';
    }

    getFullOperationalReport() {
        let mOccurancesKeys = [];
        let datasets = [];
        let event_occurences = this.state.items.event_occurences;
        if(event_occurences !== undefined) {
            mOccurancesKeys = Object.keys(event_occurences[0].types);
            event_occurences.map((mEvent) => {
                const dataset = {};
                dataset['label'] = mEvent.sensor;
                dataset['data'] = Object.values(mEvent.types);
                dataset['backgroundColor'] = this.generateColor();
                datasets.push(dataset);
            });
        }

        const occurances = {
            labels: mOccurancesKeys,
            datasets: datasets
        };

        return (
            <React.Fragment>
                <div className="p-md-12">
                    <h4>Event Occurances</h4>
                    <Chart
                        type="bar"
                        data={occurances}
                        options={{
                            legend: {
                                display: true
                            },
                            responsive:true,
                            title: { text: "Event Occurances By Category", display: true },
                            scales:{
                                yAxes:[{
                                    ticks:{
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }

    componentDidMount() {
        if(this.props.location.report !== undefined) {
            this.productServiceDataSources.getOperationalReportsInfo(this.props.location.report).then(data => this.setState({ items: data }));
        }
    }

    render() {
        return (
            <div className="p-grid dashboard">
                <div className="p-col-12 global-sales">
                    <h1>{this.getOperationalData()}</h1>
                    <div className="card" style={{cursor: 'pointer'}}>
                        {this.getFullOperationalReport()}
                    </div>
                </div>
            </div>
        );
    }
}

import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/components/chart/Chart';
import axios from 'axios';

export const Health = () => {
    const [userDBData, setUserDBData]  = useState({});
    const [userCPUData, setUserCPUData]  = useState({});
    const [userStateData, setUserStateData]  = useState({});
    const [userTimeData, setUserTimeData]  = useState({});
    const bgColor = ['rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
    ];

    const bdColor = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
    ];

    const hoverBgColor = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#1FDE56"
    ];

    const bdWidth = 1;

    var sys_health_data = {
        method: 'GET',
        url: '../assets/demo/data/health-data.json',
    };

    const circleGraphSupport = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    const mLabel = {
        indexLabelPlacement: 'inside'
    };

    const DBErrorApi = () => {
        axios.request(sys_health_data)
        .then(res=> {
            var items_db_x = [];
            var items_db_y = [];
            for(let i = 0; i < res.data.data_base.length; i++) {
                items_db_x.push(res.data.data_base[i].severity);
                items_db_y.push(res.data.data_base[i].value);
            }

            setUserDBData({
                labels: items_db_x,
                datasets: [
                {
                    data: items_db_y,
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth,
                    hoverBackgroundColor: hoverBgColor
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const CPUsageApi = () => {
        axios.request(sys_health_data)
        .then(res=> {
            var items_cpu_x = [];
            var items_cpu_y = [];
            for(let i = 0; i < res.data.data_cpu.length; i++) {
                items_cpu_x.push(res.data.data_cpu[i].app);
                items_cpu_y.push(res.data.data_cpu[i].value);
            }

            setUserCPUData({
                labels: items_cpu_x,
                datasets: [
                {
                    data: items_cpu_y,
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth,
                    hoverBackgroundColor: hoverBgColor
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const ServerStateApi = () => {
        axios.request(sys_health_data)
        .then(res=> {
            var items_state_x = [];
            var items_state_y = [];
            for(let i = 0; i < res.data.data_state.length; i++) {
                items_state_x.push(res.data.data_state[i].source);
                items_state_y.push(res.data.data_state[i].value);
            }

            setUserStateData({
                labels: items_state_x,
                datasets: [
                {
                    data: items_state_y,
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth,
                    hoverBackgroundColor: hoverBgColor
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const UpDownTimeApi = () => {
        axios.request(sys_health_data)
        .then(res=> {
            var items_time_x = [];
            var items_time_y = [];
            for(let i = 0; i < res.data.data_time.length; i++) {
                items_time_x.push(res.data.data_time[i].state);
                items_time_y.push(res.data.data_time[i].value);
            }

            setUserTimeData({
                labels: items_time_x,
                datasets: [
                {
                    data: items_time_y,
                    backgroundColor: bgColor,
                    borderColor: bdColor,
                    borderWidth: bdWidth,
                    hoverBackgroundColor: hoverBgColor
                }]
            });
        })
        .catch(err =>{
            console.log(err);
        })
    }

    useEffect(() => {
        DBErrorApi();
        CPUsageApi();
        ServerStateApi();
        UpDownTimeApi();
    }, []);

    return (
        <div className="p-grid dashboard">
            <div className="p-col-12 p-lg-12">
                <h1>System Health Status</h1>
            </div>
            <div className="p-col-12 p-lg-6 global-sales">
                <div className="card">
                    <h5>Database Errors</h5>
                    <Chart
                        type="bar"
                        data={userDBData}
                        options={{
                            legend: {
                                display: false
                            },
                            responsive:true,
                            title: { text: "Database Errors", display: true },
                            scales:{
                                yAxes:[{
                                    ticks:{
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }}
                    />
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-6 global-sales">
                <div className="card">
                    <h5>Application CPU Usage</h5>
                    <Chart type="polarArea" data={userCPUData} options={circleGraphSupport}/>
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-6 global-sales">
                <div className="card">
                    <h5>Data Source Server State</h5>
                    <Chart type="pie" data={userStateData} options={circleGraphSupport}/>
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-6 global-sales">
                <div className="card">
                    <h5>Database Up vs Down Time</h5>
                    <Chart type="doughnut" data={userTimeData} options={circleGraphSupport}/>
                </div>
            </div>
        </div>
    );
}
